import React, { Suspense } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import UtilsContext from './context/UtilsContext';
import SalesContext from './context/SalesContext';

import Login from './views/Login';
import Error from './error/Error';
import './assets/css/Login.css';
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { routes } from './config/config';

library.add(fab, fas);

function App() {
  return (
    <Router>
      <Switch>
        <Suspense fallback={<div>Loading...</div>}>
          <UtilsContext >
            <SalesContext >
              <Switch>
                <Route path="/" render={prop => <Login {...prop} />} exact />
                {routes.map((data, i) => {
                  return <Route
                    key={i}
                    path={data.path}
                    render={prop => <data.component {...prop} title={data.title} />} />
                })}
                <Route render={prop => <Error {...prop} />} exact />
              </Switch>
            </SalesContext>
          </UtilsContext>
        </Suspense>
      </Switch>
    </Router>
  );
}

export default App;
