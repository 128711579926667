import React, { useReducer, createContext } from 'react';
import moment from 'moment';
import {
  ADD_SALES,
  GET_SALES,
  UPDATE_SALES,
  MARK_SALES_COLLECTED,
  GET_CUSTOMERS,
  ADD_CUSTOMER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  // DELETE_SALES,
  reducer,
} from './SalesReducer';
import axios from 'axios';
import { displayErrors, displayNotification } from '../config/display';
import { headers } from '../config/token';

export const SContext = createContext({});

const SalesContext = ({ children }) => {

  const API = process.env.REACT_APP_API_URL;

  const defaultValue = {
    customersList: [],
    salesList: [],
    salesListLength: 0
  }

  const [sales, dispatch] = useReducer(reducer, defaultValue);

  const getCustomer = () => axios.get(API + `salesms/customer`, headers());

  // actions
  const getInvoiceAndCustomers = (setLoading, filter, paginate) => {

    const { page, pageSize } = paginate;
    const {
      sort,
      showRecord,
      search,
      month,
      year,
      customer,
      currency,
      orNumber,
    } = filter;
    let filter_params = `&sort=${sort}&showRecord=${showRecord}`;

    if (search !== '' && search && search.trim() !== "" && search !== null)
      filter_params += `&search=${search}`;

    if (orNumber !== '' && orNumber && orNumber.trim() !== "" && orNumber !== null)
      filter_params += `&orNumber=${orNumber}`;

    if (month)
      filter_params += `&month=${month}`;

    if (year)
      filter_params += `&year=${year}`;

    if (customer)
      filter_params += `&customer=${customer}`;

    if (currency)
      filter_params += `&currency=${currency}`;


    setLoading(true);
    const getInvoice = () => axios.get(API + `salesms/invoice?page=${page}&pageSize=${pageSize}${filter_params}`, headers());

    axios.all([getInvoice(), getCustomer()])
      .then(axios.spread((invoice, customer) => {

        const { customersList } = customer.data;
        const { salesList, salesListLength } = invoice.data;
        dispatch({
          type: GET_SALES,
          payload: {
            customersList,
            salesList,
            salesListLength
          }
        })
        setLoading(false);
      }))
      .catch(err => {
        setLoading(false);
        displayErrors(err);
      })

  }

  const createSales = (values, setLoading, resetForm, setSubmitting) => {
    setLoading(true);

    axios.post(API + `salesms/invoice`, values, headers())
      .then(res => {
        const { newSales, message } = res.data;

        dispatch({
          type: ADD_SALES,
          payload: {
            newSales
          }
        });
        setLoading(false);
        setSubmitting(false);
        resetForm();
        displayNotification("success", message);
      })
      .catch(err => {
        setLoading(false);
        displayErrors(err);
      })

  }

  const updateSales = (values, setLoading, closeModal) => {
    setLoading(true);

    axios.put(API + `salesms/invoice/${values.id}`, values, headers())
      .then(res => {
        const { updatedSales, message } = res.data;

        dispatch({
          type: UPDATE_SALES,
          payload: {
            updatedSales
          }
        });
        setLoading(false);
        closeModal();
        displayNotification("success", message);
      })
      .catch(err => {
        setLoading(false);
        displayErrors(err);
      })

  }

  const deleteSales = (id, setLoading) => {
    setLoading(true);

    axios.delete(API + `salesms/invoice/${id}`, headers())
      .then(res => {
        const { message, updatedSales } = res.data;

        dispatch({
          type: UPDATE_SALES,
          payload: {
            updatedSales
          }
        });
        setLoading(false);
        displayNotification("success", message);
      })
      .catch(err => {
        setLoading(false);
        displayErrors(err);
      })
  }

  const getInvoices = (customerID) => {

    return axios.get(API + `salesms/invoice/${customerID}`, headers());

  }

  const markInvoicesAsCollected = (values, setArFormVisible, setLoading, setSubmitting) => {
    setLoading(true);

    axios.put(API + `salesms/invoice`, values, headers())
      .then(res => {
        const { message, salesList } = res.data;

        dispatch({
          type: MARK_SALES_COLLECTED,
          payload: {
            salesList,
          }
        })
        setLoading(false);

        setArFormVisible(false);
        displayNotification("success", message);
      })
      .catch(err => {
        setLoading(false);
        setSubmitting(false);
        displayErrors(err);
      })

  }

  const reviseInvoice = (id, setLoading) => {
    setLoading(true);
    axios.delete(API + `salesms/invoice/${id}/revised`, headers())
      .then(res => {
        const { message, updatedSales } = res.data;

        dispatch({
          type: UPDATE_SALES,
          payload: {
            updatedSales
          }
        });
        setLoading(false);
        displayNotification("success", message);
      })
      .catch(err => {
        setLoading(false);
        displayErrors(err);
      })

  }

  const exportSoa = (filter, setLoading) => {
    const { customer: { key, label }, currency } = filter;

    if (key === '' || label === '' || !currency) {
      displayNotification("error", "Customer & currency are required");
      return false;
    }

    if (label.toString().toLowerCase() === 'no customer') {
      displayNotification("error", "No Customer not allowed");
      return false;
    }
    setLoading(true);
    const filter_params = `?cid=${key}&currency=${currency}`;

    axios({
      url: API + `salesms/export/soa${filter_params}`,
      method: 'get',
      responseType: 'blob',
      ...headers()
    })
      .then(res => {
        displayNotification('success', 'File successfully generated');
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `${label}_SOA_${moment().format('YYYY_MM_DD')}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
        setLoading(false);

      })
      .catch(err => {
        displayErrors(err);
        setLoading(false);

      })
  }

  const validateExports = filter => {
    const { month, year, conversion } = filter;

    if (!month || !year || !conversion) {
      displayNotification("error", "Month, year & conversion are required");
      return false;
    }

    return true;
  }

  const exportAR = (filter, setLoading) => {
    const { month, year, conversion } = filter;
    if (!validateExports(filter))
      return false;

    setLoading(true);
    const filter_params = `?month=${month}&year=${year}&conversion=${conversion}`;

    axios({
      url: API + `salesms/export/ar${filter_params}`,
      method: 'get',
      responseType: 'blob',
      ...headers()
    })
      .then(res => {
        displayNotification('success', 'File successfully generated');
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `AR_${month}_${year}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
        setLoading(false);

      })
      .catch(err => {
        displayErrors(err);
        setLoading(false);

      })
  }

  const exportSalesSummary = (filter, setLoading) => {
    const { month, year, conversion } = filter;
    if (!validateExports(filter))
      return false;

    setLoading(true);
    const filter_params = `?month=${month}&year=${year}&conversion=${conversion}`;

    axios({
      url: API + `salesms/export/summary${filter_params}`,
      method: 'get',
      responseType: 'blob',
      ...headers()
    })
      .then(res => {
        displayNotification('success', 'File successfully generated');
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `Sales_summary_${month}_${year}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
        setLoading(false);

      })
      .catch(err => {
        displayErrors(err);
        setLoading(false);

      })
  }

  const exportSalesSummaryExternal = (filter, setLoading) => {
    const { month, year, conversion } = filter;
    if (!validateExports(filter))
      return false;

    setLoading(true);
    const filter_params = `?month=${month}&year=${year}&conversion=${conversion}`;

    axios({
      url: API + `salesms/export/summaryExt${filter_params}`,
      method: 'get',
      responseType: 'blob',
      ...headers()
    })
      .then(res => {
        displayNotification('success', 'File successfully generated');
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `Sales_summary_external_${month}_${year}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
        setLoading(false);

      })
      .catch(err => {
        displayErrors(err);
        setLoading(false);

      })
  }

  const exportSales = (filter, setLoading) => {

    const { conversion, year, month, customer } = filter;
    if (!conversion || !year) {
      displayNotification("error", "Conversion & year is required");
      return false;
    }

    setLoading(true);
    axios({
      url: API + `salesms/export/sales?conversion=${conversion}&year=${year}&month=${month}&customer=${customer.key}`,
      method: 'get',
      responseType: 'blob',
      ...headers()
    })
      .then(res => {
        displayNotification('success', 'File successfully generated');
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `Sales_as_for_year_${year}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
        setLoading(false);

      })
      .catch(err => {
        displayErrors(err);
        setLoading(false);

      })
  }

  const exportCRB = (filter, setLoading) => {
    const { month, year } = filter;
    if (!month || !year) {
      displayNotification("error", "Month & year are required");
      return false;
    }

    setLoading(true);
    const filter_params = `?month=${month}&year=${year}`;

    axios({
      url: API + `salesms/export/crb${filter_params}`,
      method: 'get',
      responseType: 'blob',
      ...headers()
    })
      .then(res => {
        displayNotification('success', 'File successfully generated');
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `CashReceipt_${month}_${year}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
        setLoading(false);

      })
      .catch(err => {
        displayErrors(err);
        setLoading(false);

      })
  }

  const exportWeeklyAR = (filter, setLoading) => {
    const { week, year, conversion } = filter;
    if (!week || !year|| !conversion) {
      displayNotification("error", "Week, Conversion & year are required");
      return false;
    }

    setLoading(true);
    const filter_params = `?week=${week}&year=${year}&conversion=${conversion}`;

    axios({
      url: `${API}salesms/export/arweekly${filter_params}`,
      method: 'get',
      responseType: 'blob',
      ...headers()
    })
      .then(res => {
        displayNotification('success', 'File successfully generated');
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `Weekly_AR_${week}_${year}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
        setLoading(false);

      })
      .catch(err => {
        displayErrors(err);
        setLoading(false);

      })
  }

  // customer
  const getCustomerList = (setLoading) => {
    setLoading(true);
    getCustomer()
      .then(res => {
        const { customersList } = res.data;

        dispatch({
          type: GET_CUSTOMERS,
          payload: {
            customersList
          }
        })

        setLoading(false);
      })
      .catch(err => {
        displayErrors(err);
        setLoading(false);
      })

  }

  const addCustomer = (values, setLoading, resetForm, setSubmitting) => {
    setLoading(true);

    axios.post(API + `salesms/customer`, values, headers())
      .then(res => {

        const { newCustomer, message } = res.data;

        dispatch({
          type: ADD_CUSTOMER,
          payload: {
            newCustomer
          }
        });
        displayNotification("success", message);
        setLoading(false);
        resetForm();
      })
      .catch(err => {
        displayErrors(err);
        setLoading(false);
        setSubmitting(false);
      })
  }

  const updateCustomer = (values, setLoading, resetForm, setSubmitting) => {
    setLoading(true);

    axios.put(API + `salesms/customer/${values.id}`, values, headers())
      .then(res => {

        const { newCustomer, message } = res.data;

        dispatch({
          type: UPDATE_CUSTOMER,
          payload: {
            newCustomer
          }
        });
        displayNotification("success", message);
        setLoading(false);
        resetForm();
      })
      .catch(err => {
        displayErrors(err);
        setLoading(false);
        setSubmitting(false);
      })
  }

  const deleteCustomer = (id, setLoading, resetForm) => {
    setLoading(true);

    axios.delete(API + `salesms/customer/${id}`, headers())
      .then(res => {

        const { message } = res.data;

        dispatch({
          type: DELETE_CUSTOMER,
          payload: {
            id
          }
        });
        displayNotification("success", message);
        setLoading(false);
        resetForm();
      })
      .catch(err => {
        displayErrors(err);
        setLoading(false);
      })
  }

  const getOR = (search) => axios.get(`${API}salesms/or?search=${search}`, headers())
    .then((res) => {
      const { orList } = res.data;
      return [ ...orList ];
    })
    .catch((err) => {
      console.log(err);
      throw new Error('Something wrong with the server');
    })

  return (
    <SContext.Provider
      value={{
        sales,
        getInvoiceAndCustomers,
        createSales,
        updateSales,
        deleteSales,
        markInvoicesAsCollected,
        reviseInvoice,
        getInvoices,
        getCustomerList,
        exportSoa,
        exportAR,
        exportSalesSummary,
        exportSalesSummaryExternal,
        exportSales,
        addCustomer,
        updateCustomer,
        deleteCustomer,
        getOR,
        exportCRB,
        exportWeeklyAR,
      }}
    >
      {children}
    </SContext.Provider>
  )
}

export default SalesContext
