export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const GET_SALES = 'GET_SALES';
export const ADD_SALES = 'ADD_SALES';
export const UPDATE_SALES = 'UPDATE_SALES';
export const DELETE_SALES = 'DELETE_SALES';
export const MARK_SALES_COLLECTED = 'MARK_SALES_COLLECTED';

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CUSTOMERS:
      return {
        ...state,
        customersList: payload.customersList,
      }
    case ADD_CUSTOMER:
      return {
        ...state,
        customersList: [payload.newCustomer, ...state.customersList]
      }
    case UPDATE_CUSTOMER:
      return {
        ...state,
        customersList: [...state.customersList.slice(0, state.customersList.findIndex(c =>
          c.id === payload.newCustomer.id)),
        { ...payload.newCustomer },
        ...state.customersList.slice(state.customersList.findIndex(c =>
          c.id === payload.newCustomer.id) + 1)
        ]
      }
    case DELETE_CUSTOMER:
      return {
        ...state,
        customersList: state.customersList.filter(c => c.id !== payload.id),
      }
    case GET_SALES:
      return {
        ...state,
        customersList: payload.customersList,
        salesList: payload.salesList,
        salesListLength: payload.salesListLength
      }
    case ADD_SALES:
      return {
        ...state,
        salesList: [payload.newSales, ...state.salesList],
        salesListLength: state.salesListLength + 1
      }
    case UPDATE_SALES:
      return {
        ...state,
        salesList: [...state.salesList.slice(0, state.salesList.findIndex(sales =>
          sales.id === payload.updatedSales.id)),
        { ...payload.updatedSales },
        ...state.salesList.slice(state.salesList.findIndex(sales =>
          sales.id === payload.updatedSales.id) + 1)
        ]
      }
    case DELETE_SALES:
      return {
        ...state,
        salesList: state.salesList.filter(sales => sales.id !== payload.id),
      }
    case MARK_SALES_COLLECTED:
      return {
        ...state,
        salesList: state.salesList.map(sales => {

          const index = payload.salesList.findIndex(s => s.id === sales.id);
          if (index !== -1)
            return payload.salesList[index];
          else
            return sales;
        })
      }
    default:
      return state;
  }


}
