import { lazy } from 'react';
const SalesView = lazy(() => import('../views/SalesView'));
const ReportsView = lazy(() => import('../views/ReportsView'));
const CustomerView = lazy(() => import('../views/CustomerView'));

export const brandname_lg = { name: 'SALES', suffix: 'MS' }; //BRANDNAME TO BE DISPLAY WHEN ON LG MODE,MAX OF 10 CHARACTERS FOR NAME AND 5 FOR SUFFIX 
export const brandname_sm = ' SALESMS'  //SHORTER NAME or acronym for BRANDNAME TO BE DISPLAY WHEN ON MOBILE SIZE, MAX OF 8 CHARACTERS ONLY
export const redirect_path = '/sales'; //redirect path if successfully logged in
export const API = process.env.REACT_APP_API_URL; //api link

//https://ant.design/components/icon/ for icons
export const routes = [ //array for routes
  {
    component: SalesView,
    title: 'Sales',
    icon: 'receipt',
    view: true, //change to false if you dont want this route to be on sidebar menu
    path: '/sales'
  },
  {
    component: CustomerView,
    title: 'Customers',
    icon: 'users',
    view: true, //change to false if you dont want this route to be on sidebar menu
    path: '/cusstomers'
  },
  {
    component: ReportsView,
    title: 'Reports',
    icon: 'chart-bar',
    view: true, //change to false if you dont want this route to be on sidebar menu
    path: '/reports'
  }
]