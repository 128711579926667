import React from 'react';

import { Redirect } from 'react-router-dom';
import { redirect_path } from '../config/config';
import { isLoggedIn } from '../config/token';
import LoginForm from '../components/Login/LoginForm';

const Login = () => {

  if(isLoggedIn())
    return <Redirect to={redirect_path} />

  return (
    <div className="logincontainer">
      <LoginForm />
    </div>
  )
}

export default Login
